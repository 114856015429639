/** @format */

export enum ImageType {
    HEADER = require('../../assets/images/header.png'),
    WHY_0 = require('../../assets/images/why_0.png'),
    WHY_1 = require('../../assets/images/why_1.png'),
    WHY_2 = require('../../assets/images/why_2.png'),
    WHY_3 = require('../../assets/images/why_3.png'),
    HOW_WORKS_0 = require('../../assets/images/howWorks/howWorks_0.webp'),
    WHAT_ELSE_1 = require('../../assets/images/whatElse_1.png'),
    PERSON_ANDREW_ALEKSANDROV = require('../../assets/images/team/andrew-aleksandrov.jpeg'),
    PERSON_ANNA_VOEVODINA = require('../../assets/images/team/anna-voevodina.jpeg'),
    PERSON_DENYS_SHESTAK = require('../../assets/images/team/denys-shestak.jpeg'),
    PERSON_MYKOLA_DEMCHUK = require('../../assets/images/team/mykola-demchuk.jpeg'),
    PERSON_NIKITA_RASKOSOV = require('../../assets/images/team/nikita-raskosov.jpeg'),
    PERSON_SID_PANDA = require('../../assets/images/team/sid-panda.jpeg'),
    PERSON_SLAVA_DEMCHUK = require('../../assets/images/team/slava-demchuk.jpeg'),
    PERSON_VASILY_VIDMANOV = require('../../assets/images/team/vasily-vidmanov.jpeg'),
    PERSON_VLAD_RASKOSOV = require('../../assets/images/team/vlad-raskosov.jpeg'),
    REVIEW_0 = require('../../assets/images/reviews/review_0.png'),
    REVIEW_1 = require('../../assets/images/reviews/review_1.png'),
    REVIEW_2 = require('../../assets/images/reviews/review_2.png'),
    REVIEW_3 = require('../../assets/images/reviews/review_3.png'),
    PARTNER_0 = require('../../assets/images/partners/partner_0.png'),
    PARTNER_1 = require('../../assets/images/partners/partner_1.png'),
    PARTNER_2 = require('../../assets/images/partners/partner_2.png'),
    PARTNER_3 = require('../../assets/images/partners/partner_3.png'),
    PARTNER_4 = require('../../assets/images/partners/partner_4.png'),
    PARTNER_5 = require('../../assets/images/partners/partner_5.png'),
    PARTNER_6 = require('../../assets/images/partners/partner_6.png'),
    PARTNER_7 = require('../../assets/images/partners/partner_7.png'),
    PARTNER_8 = require('../../assets/images/partners/partner_8.png'),
    PARTNER_9 = require('../../assets/images/partners/partner_9.png'),
    PARTNER_10 = require('../../assets/images/partners/partner_10.png'),
    PARTNER_11 = require('../../assets/images/partners/partner_11.png'),
    PARTNER_12 = require('../../assets/images/partners/partner_12.png'),
    PARTNER_13 = require('../../assets/images/partners/partner_13.png'),
    PARTNER_14 = require('../../assets/images/partners/partner_14.png'),
    RISKS = require('../../assets/images/risks/stats.png'),
    WALLET_3MINT = require('../../assets/images/modal/subModal/3mint.png'),
    WALLET_37X = require('../../assets/images/modal/subModal/37x.png'),
    WALLET_ABSOLUTE = require('../../assets/images/modal/subModal/absolute.png'),
    WALLET_AMBIRE = require('../../assets/images/modal/subModal/ambire.jfif'),
    WALLET_AT = require('../../assets/images/modal/subModal/at.png'),
    WALLET_BC = require('../../assets/images/modal/subModal/bc.png'),
    WALLET_BITSKI = require('../../assets/images/modal/subModal/bitski.png'),
    WALLET_BITWINEX = require('../../assets/images/modal/subModal/bitwinex.png'),
    WALLET_BLADE = require('../../assets/images/modal/subModal/blade.png'),
    WALLET_BLANQ = require('../../assets/images/modal/subModal/blanq.png'),
    WALLET_BLOOM = require('../../assets/images/modal/subModal/bloom.png'),
    WALLET_CAMPUX = require('../../assets/images/modal/subModal/campux.jfif'),
    WALLET_CAPSULE = require('../../assets/images/modal/subModal/capsule.png'),
    WALLET_CERTHIS = require('../../assets/images/modal/subModal/certhis.png'),
    WALLET_CLAVE = require('../../assets/images/modal/subModal/clave.png'),
    WALLET_COIN = require('../../assets/images/modal/subModal/coin.png'),
    WALLET_COINSTATS = require('../../assets/images/modal/subModal/coinstats.png'),
    WALLET_CROSSMINT = require('../../assets/images/modal/subModal/crossmint.png'),
    WALLET_CYBER = require('../../assets/images/modal/subModal/cyber.png'),
    WALLET_CYPHEROCK = require('../../assets/images/modal/subModal/cypherock.png'),
    WALLET_DEFICLOUD = require('../../assets/images/modal/subModal/deficloud.png'),
    WALLET_DEXTRADE = require('../../assets/images/modal/subModal/dextrade.png'),
    WALLET_DMTOKEN = require('../../assets/images/modal/subModal/dmtoken.png'),
    WALLET_DREAM = require('../../assets/images/modal/subModal/dream.png'),
    WALLET_DC = require('../../assets/images/modal/subModal/ds.jfif'),
    WALLET_EARTH = require('../../assets/images/modal/subModal/earth.png'),
    WALLET_FACE = require('../../assets/images/modal/subModal/face.jfif'),
    WALLET_FERAL = require('../../assets/images/modal/subModal/feral.png'),
    WALLET_FIREBLOCKS = require('../../assets/images/modal/subModal/fireblocks.png'),
    WALLET_FLOOZ = require('../../assets/images/modal/subModal/flooz.png'),
    WALLET_GAMIC = require('../../assets/images/modal/subModal/gamic.jfif'),
    WALLET_GATE = require('../../assets/images/modal/subModal/gate.jfif'),
    WALLET_GATEWEB3 = require('../../assets/images/modal/subModal/gateweb3.png'),
    WALLET_GOODDOLAR = require('../../assets/images/modal/subModal/gooddolar.png'),
    WALLET_HASHPACK = require('../../assets/images/modal/subModal/hashpack.jfif'),
    WALLET_HELLO = require('../../assets/images/modal/subModal/hello.png'),
    WALLET_HOO = require('../../assets/images/modal/subModal/hoo.png'),
    WALLET_HYPER = require('../../assets/images/modal/subModal/hyper.png'),
    WALLET_INFINITY = require('../../assets/images/modal/subModal/infinity.png'),
    WALLET_INTERNET = require('../../assets/images/modal/subModal/internet.png'),
    WALLET_JOYID = require('../../assets/images/modal/subModal/joyid.png'),
    WALLET_KABILA = require('../../assets/images/modal/subModal/kabila.jfif'),
    WALLET_KAX = require('../../assets/images/modal/subModal/kax.png'),
    WALLET_KEEPKEY = require('../../assets/images/modal/subModal/keepkey.png'),
    WALLET_KEYCHAIN = require('../../assets/images/modal/subModal/keychain.png'),
    WALLET_LEDGER = require('../../assets/images/modal/subModal/ledger.png'),
    WALLET_LEVAIN = require('../../assets/images/modal/subModal/levain.png'),
    WALLET_LICHTBIT = require('../../assets/images/modal/subModal/lichtbit.png'),
    WALLET_LODE = require('../../assets/images/modal/subModal/lode.png'),
    WALLET_MARBLE = require('../../assets/images/modal/subModal/marble.png'),
    WALLET_MELD = require('../../assets/images/modal/subModal/meld.png'),
    WALLET_META_WON = require('../../assets/images/modal/subModal/meta-won.png'),
    WALLET_META = require('../../assets/images/modal/subModal/meta.jfif'),
    WALLET_METER = require('../../assets/images/modal/subModal/meter.png'),
    WALLET_MOONPAY = require('../../assets/images/modal/subModal/moonpay.png'),
    WALLET_MPCVAULT = require('../../assets/images/modal/subModal/mpcvault.png'),
    WALLET_MULTIX = require('../../assets/images/modal/subModal/multix.png'),
    WALLET_MUZA = require('../../assets/images/modal/subModal/muza.png'),
    WALLET_MY = require('../../assets/images/modal/subModal/my.png'),
    WALLET_NEON = require('../../assets/images/modal/subModal/neon.png'),
    WALLET_NOVA = require('../../assets/images/modal/subModal/nova.png'),
    WALLET_NOW = require('../../assets/images/modal/subModal/now.png'),
    WALLET_NUFI = require('../../assets/images/modal/subModal/nufi.png'),
    WALLET_NUFINETES = require('../../assets/images/modal/subModal/nufinetes.png'),
    WALLET_NUMO = require('../../assets/images/modal/subModal/numo.png'),
    WALLET_OISY = require('../../assets/images/modal/subModal/oisy.png'),
    WALLET_OKTO = require('../../assets/images/modal/subModal/okto.png'),
    WALLET_ONEKEY = require('../../assets/images/modal/subModal/onekey.png'),
    WALLET_PAPER = require('../../assets/images/modal/subModal/paper.png'),
    WALLET_PUZZLE = require('../../assets/images/modal/subModal/puzzle.png'),
    WALLET_ROAM = require('../../assets/images/modal/subModal/roam.jfif'),
    WALLET_ROVI = require('../../assets/images/modal/subModal/rovi.png'),
    WALLET_SAHAL = require('../../assets/images/modal/subModal/sahal.png'),
    WALLET_SEQUEL = require('../../assets/images/modal/subModal/sequel.png'),
    WALLET_SEQUENCE = require('../../assets/images/modal/subModal/sequence.png'),
    WALLET_SHAPESHIFT = require('../../assets/images/modal/subModal/shapeshift.png'),
    WALLET_SHIMMERSEA = require('../../assets/images/modal/subModal/shimmersea.png'),
    WALLET_SMARTRUSH = require('../../assets/images/modal/subModal/smartrush.jfif'),
    WALLET_SOLACE = require('../../assets/images/modal/subModal/solace.png'),
    WALLET_STICKEY = require('../../assets/images/modal/subModal/stickey.png'),
    WALLET_THIRDWEB = require('../../assets/images/modal/subModal/thirdweb.png'),
    WALLET_TORUS = require('../../assets/images/modal/subModal/torus.png'),
    WALLET_UNIPASS = require('../../assets/images/modal/subModal/unipass.png'),
    WALLET_UTORG = require('../../assets/images/modal/subModal/utorg.png'),
    WALLET_VENLY = require('../../assets/images/modal/subModal/venly.png'),
    WALLET_WALLACY = require('../../assets/images/modal/subModal/wallacy.png'),
    WALLET_WOMBAT = require('../../assets/images/modal/subModal/wombat.png'),
    WALLET_ZELCORE = require('../../assets/images/modal/subModal/zelcore.jfif'),
    WALLET_ZERION = require('../../assets/images/modal/subModal/zerion.png'),
}
